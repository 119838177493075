import React from "react";
import { DeviceFilterContext } from "../context/DeviceFilterProvider";
import { Icon } from "../helpers/icons";
import { XIcon } from "@heroicons/react/outline";
import { AllowLocation } from "./AllowLocation";

function List({ filters, setFilters }) {
  return filters
    .filter((filter) => filter.category === "Device type")
    .map((filter) => (
      <div key={filter.value + "_" + filter.label} className="flex">
        <FilterDeviceButton filter={filter} setFilters={setFilters} />
      </div>
    ));
}

function FilterDeviceButton({ filter, setFilters }) {
  const toggleChecked = !filter.checked;
  const handleChange = () => {
    setFilters((prevState) => {
      let items = [...prevState];
      items.forEach((row) => {
        if (filter.value === row.value) {
          row.checked = toggleChecked;
        } else {
          row.checked = false
        }
      });
      return items;
    });
  };

  return (
    <div
      className="mb-2"
      style={{
        padding: "2px",
        background: "rgba(0,0,0,.2)",
        borderRadius: "99px",
      }}
    >
      <div
        className={`cursor-pointer  rounded-full p-2 ${
          filter.checked ? "bg-black" : "bg-white"
        }`}
        onClick={handleChange}
      >
        <Icon
          type={filter.value}
          color={filter.checked ? "white" : "#3B82F6"}
        />
      </div>
    </div>
  );
}

function ClearDeviceFilter({ filters, setFilters }) {
  const isAnyFilterSelected = filters.findIndex((e) => e.checked) !== -1;

  const handleClear = ({ setFilters }) => {
    setFilters((prevState) => {
      let items = prevState.map((e) => {
        e.checked = false;
        return e;
      });
      return items;
    });
  };

  if (isAnyFilterSelected) {
    return (
      <div
        className="mb-2 w-7 mx-auto"
        style={{
          padding: "2px",
          background: "rgba(0,0,0,.2)",
          borderRadius: "99px",
        }}
      >
        <div
          className="w-6 h-6 bg-red-500 rounded-full p-1 mx-auto cursor-pointer"
          onClick={() => handleClear({ setFilters })}
        >
          <XIcon className="text-white" />
        </div>
      </div>
    );
  }
  return null;
}

function Location({ mapRef }) {
  return (
    <AllowLocation mapRef={mapRef}>
      <div
        className="mb-2"
        style={{
          padding: "2px",
          background: "rgba(0,0,0,.2)",
          borderRadius: "99px",
        }}
      >
        <div className={`cursor-pointer rounded-full p-2 bg-white`}>
          <Icon type="location" />
        </div>
      </div>
    </AllowLocation>
  );
}

export function DeviceFilter({ mapRef }) {
  return (
    <div className="absolute z-10 right-4 top-4">
      <DeviceFilterContext.Consumer>
        {({ filters, setFilters }) => (
          <>
            <Location mapRef={mapRef} />
            <List filters={filters} setFilters={setFilters} />
            {/* <ClearDeviceFilter filters={filters} setFilters={setFilters} /> */}
          </>
        )}
      </DeviceFilterContext.Consumer>
    </div>
  );
}
