import React, { useState, useEffect } from "react";
export const DevicesContext = React.createContext();

export function DeviceContextProvider({ children }) {
  const [devices, setDevices] = useState([]);

  function updateDevices() {
    return fetch("https://api.vuumly.com/api/availableDevices")
      .then((response) => response.json())
      .then((data) => {
        setDevices(data)
      })
  }

  useEffect(() => {
    updateDevices()
  }, []);

  return (
    <DevicesContext.Provider value={{ devices, setDevices, updateDevices }}>
      {children}
    </DevicesContext.Provider>
  );
}
