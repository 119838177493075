import React, { useEffect } from "react";
import Div100vh from "react-div-100vh";

import Map from "./components/Map";
import Header from "./components/Header";
import SlideOver from "./components/SlideOver";
import SlideOverWithBg from "./components/SlideOverWithBg";

import { DeviceFilterProvider } from "./context/DeviceFilterProvider";
import { SelectedDeviceContextProvider } from "./context/SelectedDeviceContextProvider";

import {
  DeviceContextProvider,
} from "./context/DevicesContextProvider";

import ReactGA from "react-ga4";

import "./App.css";

function AppContent() {
  return (
    <Div100vh>
      <div className="flex flex-col h-full">
        {/* <Header/> */}
        <SlideOver />
        <SlideOverWithBg />
        <Map />
      </div>
    </Div100vh>
  );
}

function App() {
  useEffect(() => {
    ReactGA.initialize("G-ZY1935N3F6");
    ReactGA.send({ hitType: "pageview", page: "/" });
  }, []);

  return (
    <SelectedDeviceContextProvider>
      <DeviceFilterProvider>
      <DeviceContextProvider>
        <AppContent />
        </DeviceContextProvider>
      </DeviceFilterProvider>
    </SelectedDeviceContextProvider>
  );
}
export default App;
