import React, { useState } from 'react';

export const SelectedDeviceContext = React.createContext();

export function SelectedDeviceContextProvider({ children }) {
    //const [selectedDevice, setSelectedDevice] = useState([])

    function handleSetSelectedDevice(device) {
        // console.log("Selected device", device)
       // setSelectedDevice(device)
    }
    return <SelectedDeviceContext.Provider value={{ handleSetSelectedDevice}}>
        {children}
    </SelectedDeviceContext.Provider>
}