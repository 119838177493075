import React, { useState, useEffect } from 'react';
import { db } from "../services/firebase";

export const DevicesSettingsContext = React.createContext();

export function DevicesSettingsContextProvider({ children }) {
    const [devicesSettings, setDeviceSettings] = useState([])

    function handleSetDevicesSettings(data) {
        setDeviceSettings(data)
    }

    useEffect(() => {
        const refSettings = db.ref(`settings`);

        refSettings.once("value", snapshot => {
            console.log("SETTINGS RECEIVED")
            handleSetDevicesSettings(snapshot.val())
        });

        return () => {
            refSettings.off()
        }

    }, [])

    return <DevicesSettingsContext.Provider value={{ devicesSettings, handleSetDevicesSettings }}>
        {children}
    </DevicesSettingsContext.Provider>
}