/* eslint-disable */
import MarkerClusterGroup from "react-leaflet-markercluster";
/* eslint-enable */
import React, { useEffect } from "react";

import {
  DevicesContext,
  DeviceContextProvider,
} from "../context/DevicesContextProvider";
import { DeviceFilterContext } from "../context/DeviceFilterProvider";
import {
  DevicesSettingsContextProvider,
  DevicesSettingsContext,
} from "../context/DevicesSettingsContextProvider";

import { createIcon, handleClick } from "./DeviceMarker";

import * as L from "leaflet";
import { getLocation, user_location } from "./AllowLocation";


import { fitClosest } from "../helpers/fitClosest";



function FiltredDevices({
  devices,
  filters,
  mapRef,
  handleSetSelectedDevice,
  selectedDevice,
  devicesSettings,
  setDevices,
}) {
  // Re render component if map is moved
  // const [value, setValue] = useState(0);

  // function handleMapMoved() {
  //   setValue((value) => value + 1);
  // }

  // useEffect(() => {
  //     mapRef.on('moveend', handleMapMoved);
  //     return () => {
  //         mapRef.off('moveend', handleMapMoved)
  //     }
  // }, [])

  // Create filters to work with
  const companies = [];
  const deviceTypes = [];
  let isAnyCompanySelected = false;
  let isAnyDeviceSelected = false;

  filters.forEach((e) => {
    if (e.category === "Company") {
      if (e.checked) {
        console.log("company selected");
        isAnyCompanySelected = true;
      }
      companies[e.value] = e.checked;
      return;
    }

    if (e.category === "Device type") {
      if (e.checked) {
        isAnyDeviceSelected = true;
      }
      deviceTypes[e.value] = e.checked;
      return;
    }
  });

  // // Filter devices
  let filtered = [];

  devices.forEach((device) => {
    if (isAnyCompanySelected) {
      if (!companies[device.company_id]) {
        return;
      }
    }

    if (isAnyDeviceSelected) {
      if (!deviceTypes[device.device_type]) {
        return;
      }
    }

    filtered.push(device);
  });

  // Find closes devices to the center of the map
  let rows = [];

  const renderDevices = filtered;

  // Push devices to render
  renderDevices.forEach((device, index) => {
    rows.push(device);
  });

  return rows;
}

function updateProgressBar(processed, total, elapsed, layersArray) {
  if (processed === total) {
    console.log(`Marker cluster render in ${elapsed} ms`);
  }
}

const mcg = L.markerClusterGroup({
  chunkedLoading: true,
  showCoverageOnHover: false,
  chunkProgress: updateProgressBar,
});

export let filteredDevices = [];

const MarkersCluster = ({
  devices,
  mapRef,
  setDevices,
  devicesSettings,
  filters,
}) => {
  filteredDevices = FiltredDevices({ devices, mapRef, filters });

  useEffect(() => {
    mapRef.invalidateSize();
    fitClosest({mapRef})
  }
  , [mapRef])

  useEffect(() => {
    mcg.clearLayers();

    const markerList = filteredDevices.map((device) => {
      //console.log(device)
      let m = L.marker(new L.LatLng(device.location.lat, device.location.lng));
      m.setIcon(createIcon({ device }));
      // m.on("click", () =>
      //   handleClick({ device, devices, setDevices, devicesSettings })
      // );
      return m;
    });

    mcg.addLayers(markerList);
    // mapRef.fitBounds(mcg.getBounds());
    mapRef.addLayer(mcg);

   // if (user_location) {
     // fitClosest({mapRef})
   // }
    

    return () => {
      mcg.clearLayers();
    };
  }, [filteredDevices, mapRef, filters, devices, devicesSettings, setDevices]);

  return null;
};

export default function DeviceMarkerCluster({ mapRef }) {
  return (
    // <DeviceContextProvider>
      <DevicesContext.Consumer>
        {({ devices, setDevices }) => {
          return (
            devices.length && (
              <DevicesSettingsContextProvider>
                <DevicesSettingsContext.Consumer>
                  {({ devicesSettings }) => {
                    return (
                      devicesSettings.length && (
                        <DeviceFilterContext.Consumer>
                          {({ filters }) => {
                            return (
                              <MarkersCluster
                                mapRef={mapRef}
                                devices={devices}
                                devicesSettings={devicesSettings}
                                setDevices={setDevices}
                                chunkProgress={updateProgressBar}
                                filters={filters}
                              />
                            );
                          }}
                        </DeviceFilterContext.Consumer>
                      )
                    );
                  }}
                </DevicesSettingsContext.Consumer>
              </DevicesSettingsContextProvider>
            )
          );
        }}
      </DevicesContext.Consumer>
    // </DeviceContextProvider>
  );
}
