import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { useEffect, useState } from "react";

const config = {
  apiKey: "AIzaSyDOdqJ72IIaB3_lYkEsRUmqlc2vlJ8I4ik",
  authDomain: "scooterscout-b5d37.firebaseapp.com",
  databaseURL: "https://scouter-devices.europe-west1.firebasedatabase.app/",
};

firebase.initializeApp(config);

export const db = firebase.database();
