import React, { useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { DeviceFilter } from "./DeviceFilter";
import DeviceMarkerCluster from "./DeviceMarkerCluster";
import "leaflet/dist/leaflet.css";



export default function Map() {
  const [map, setMap] = useState(null);

  return (
    <div className="z-0 h-full w-full flex-1 relative">
      {/* <Refresh/> */}
      <DeviceFilter mapRef={map} />
      <MapContainer
        className="z-0 w-full h-full"
        preferCanvas={true}
        whenCreated={setMap}
        center={[56.9481391, 24.1031061]}
        zoom={10}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {map && <DeviceMarkerCluster mapRef={map} />}
      </MapContainer>
    </div>
  );
}