import React from "react";

export let user_location = null;

function getUserLocation() {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      enableHighAccuracy: true,
      maximumAge: 0,
     // timeout: 5000,
    })
  );
}

export async function getLocation() {
  const position = await getUserLocation()

  user_location = position
  return position
} 

async function panToMyLocation({ mapRef }) {
  const { coords }  = await getLocation()
  mapRef.invalidateSize();
  mapRef.setView({ lat: coords.latitude, lng: coords.longitude }, 16);
}

export function AllowLocation({ children, mapRef }) {
  return <div onClick={() => panToMyLocation({mapRef})}>{children}</div>;
}
