import React from "react";
import * as L from "leaflet";

import { getIconUrl } from "../helpers/icons";

import DeviceMarkerPopup from "./DeviceMarkerPopup";

import CityBee from "../icons/companies/1.png";
import Carguru from "../icons/companies/2.png";
import Fiqsy from "../icons/companies/3.png";
import Bolt from "../icons/companies/4.png";
import Jungo from "../icons/companies/5.png";
import Ride from "../icons/companies/6.png";
import Skok from "../icons/companies/7.png";
import Nextbike from "../icons/companies/8.png";
import Ox from "../icons/companies/9.png";

export function handleClick({
  device,
  devicesSettings,
  handleSetSelectedDevice,
  setDevices,
}) {
  // setDevices((prevState) => {
  //   let items = [...prevState];
  //   let i = items.findIndex(
  //     (e) => e.id === device.id && e.company_id === device.company_id
  //   );
  //   let selectedIndex = items.findIndex((e) => e.selected);
  //   let prevSelected = { ...items[selectedIndex], selected: false };
  //   let item = { ...items[i], selected: !prevState[i].selected };
  //   items[i] = item;
  //   if (selectedIndex !== -1) {
  //     items[selectedIndex] = prevSelected;
  //   }

  //   return items;
  // });

  dispatchClick(
    <DeviceMarkerPopup device={device} settings={devicesSettings} />
  );
}

function dispatchClick(children) {
  const event = new CustomEvent("openSlideOver", { detail: children });
  document.dispatchEvent(event);
}

function getCompanyLogoUrl(company_id) {
  if (company_id === 1) return CityBee
  if (company_id === 2) return Carguru
  if (company_id === 3) return Fiqsy
  if (company_id === 4) return Bolt
  if (company_id === 5) return Jungo
  if (company_id === 6) return Ride
  if (company_id === 7) return Skok
  if (company_id === 8) return Nextbike
  if (company_id === 9) return Ox
  return CityBee;
}

function getColor(charge) {
  if (charge > 66) {
    return "#50E21C";
  }

  if (charge > 33) {
    return "#50E21C";
  }

  return "#FF0000";
}

export function createIcon({ device, index, isSelected = false }) {
  const width = 32;
  const height = 40;
  const pinSize = 8;

  return new L.divIcon({
    iconSize: [width, 40],
    className: "",
    html: `
        <div style="position:relative">
            <div style="z-index:1; position:relative; background-color: black; width:${width}px; height:${
      height - pinSize / 2
    }px; border-radius:4px; padding-top:4px">
                <img style="width:24px; margin-left:4px;" src="${getIconUrl(device.device_type)}"/>
                <div style="${
                  device.charge === -1 ? "display:none;" : ""
                } margin-left:4px; margin-right:4px; border-radius:2px; height:3px; width:24px; background-color: gray">
                    <div style="height:100%; border-radius:2px; width:${
                      device.charge
                    }%; background-color: ${getColor(device.charge)}"></div>
                </div>
            </div>
            <div style="position:absolute; width:${pinSize}px; height:${pinSize}px; background-color: black; bottom:-4px; left: ${
      (width - pinSize) / 2
    }px; transform: rotate(45deg)"></div>
        </div>`,
  });
}
