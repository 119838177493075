import React, { useState } from 'react';

export const DeviceFilterContext = React.createContext();

const FILTERS = [
    { value: 'scooter', label: 'Scooter', category: "Device type", checked: false },
    { value: 'car', label: 'Car' , category: "Device type", checked: false },
    // { value: 'tesla', label: 'Tesla' , category: "Device type", checked: false },
    { value: 'cargo', label: 'Cargo' , category: "Device type", checked: false },
    { value: 'moped', label: 'Moped' , category: "Device type", checked: false },
    // { value: 'ecar', label: 'Ecar' , category: "Device type", checked: false },
    { value: 'bike', label: 'Bike' , category: "Device type", checked: false },
    { value: '1', label: 'Citybee' , category: "Company", checked: false },
    { value: '2', label: 'CARGURU' , category: "Company", checked: false },
    { value: '3', label: 'Fiqsy' , category: "Company", checked: false },
    { value: '4', label: 'Bolt' , category: "Company", checked: false },
    { value: '5', label: 'Jungo' , category: "Company", checked: false },
    { value: '6', label: 'RIDE' , category: "Company", checked: false },
    { value: '7', label: 'Skok' , category: "Company", checked: false },
    { value: '8', label: 'nextbike' , category: "Company", checked: false },
];

export function DeviceFilterProvider({ children }) {
    const [filters, setFilters] = useState(FILTERS)
    return <DeviceFilterContext.Provider value={{filters, setFilters}}>
        {children}
    </DeviceFilterContext.Provider>
}