/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

export default function SlideOver() {
  const [open, setOpen] = useState(false)
  const [content, setContent] = useState(null)

  useEffect(() => {
    function openSlideOver({ detail }) {
      setOpen(true)
      setContent(detail)
    }

    document.addEventListener('openSlideOver', openSlideOver);

    return () => {
      document.removeEventListener('openSlideOver', openSlideOver);
    }
  }, [])

  function CloseButton() {
    return <div className="absolute top-0 right-0 flex pr-2 sm:-ml-10 sm:pr-4">
      <button
        type="button"
        className="rounded-md text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
        onClick={() => setOpen(false)}
      >
        <span className="sr-only">Close panel</span>
        <XIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => { }}>
        <div className="pointer-events-none bottom-0 fixed flex w-full">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-500"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="transform transition ease-in-out duration-500 sm:duration-500"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <Dialog.Panel className="mx-auto pointer-events-auto relative w-screen max-w-md">
              <div className="flex h-full flex-col overflow-y-auto bg-white pb-6 shadow-xl break-words rounded-t-3xl max-h-screen">
                <div className="relative mt-6 flex-1 px-2 sm:px-6">
                  <CloseButton />
                  {content}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
