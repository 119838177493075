import { getLocation } from "../components/AllowLocation";
import { orderByDistance } from "geolib";

import * as L from "leaflet";
import { filteredDevices } from "../components/DeviceMarkerCluster";

const closestCluster = L.markerClusterGroup();
const devicesTypeLimitBounds = 6;

const locationLayer = L.layerGroup();

function pureFitClosest({ mapRef, latitude, longitude }) {
  const points = filteredDevices.map((device) => ({
    latitude: device.location.lat,
    longitude: device.location.lng,
    data: device,
  }));

  let visible = {
    car: 0,
    scooter: 0,
    //   bike: 0,
    //  cargo: 0,
    //   ecar: 0,
  };

  mapRef.setView(new L.LatLng(latitude, longitude), 16)

  

  // const targetPoint = { lat: latitude, lng: longitude };

  // const renderDevices = orderByDistance(targetPoint, points)
  //   .filter(({ data }) => {
  //     // count how many are visible from each type
  //     if (
  //       visible[data.device_type] !== undefined &&
  //       visible[data.device_type] < devicesTypeLimitBounds
  //     ) {
  //       visible[data.device_type] = visible[data.device_type] + 1;
  //       return true;
  //     }
  //     return false;
  //   })
  //   .map(({ data }) => {
  //     return L.marker(new L.LatLng(data.location.lat, data.location.lng));
  //   });

  // closestCluster.addLayers(renderDevices);
  // mapRef.fitBounds(closestCluster.getBounds());
  // closestCluster.clearLayers();

  // add location marker
  locationLayer.clearLayers();
  let userLocationMarker = L.circleMarker(new L.LatLng(latitude, longitude), {
    fillOpacity: 1,
    fillColor: "#3B82F6",
    stroke: true,
    color: "white",
    opacity: 1
  });

  locationLayer.addLayer(userLocationMarker);
  mapRef.addLayer(locationLayer);
}

export async function fitClosest({ mapRef }) {
  await getLocation()
    .then(({ coords }) => {
      pureFitClosest({
        mapRef,
        latitude: coords.latitude,
        longitude: coords.longitude,
      });
     // console.log("proceed...", coords);
    })
    .catch((e) => {
      console.log("error", e);
    });
}
