import scooter, { ReactComponent as Scooter } from "../icons/scooter.svg";
import cargo, { ReactComponent as Cargo } from "../icons/cargo.svg";
import car, { ReactComponent as Car } from "../icons/car.svg";
import bike, { ReactComponent as Bike } from "../icons/bike.svg";
import tesla, { ReactComponent as Tesla } from "../icons/tesla.svg";
import moped, { ReactComponent as Moped } from "../icons/moped.svg";
import { ReactComponent as Location } from "../icons/my_location.svg";

export function getIconUrl(type) {
  if (type === "scooter") {
    return scooter;
  }

  if (type === "cargo") {
    return cargo;
  }

  if (type === "bike") {
    return bike;
  }

  if (type === "ebike") {
    return moped;
  }

  if (type === "tesla") {
    return tesla;
  }

  if (type === "moped") {
    return moped;
  }

  return car;
}

let style = { style: { width: "24px", height: "24px" } };

export function Icon({ type, color = "black" }) {
  if (type === "scooter") {
    return <Scooter fill={color} {...style} />;
  }

  if (type === "cargo") {
    return <Cargo fill={color} {...style} />;
  }

  if (type === "bike") {
    return <Bike fill={color} {...style} />;
  }

  if (type === "location") {
    return <Location fill={color} {...style} />;
  }

  if (type === "tesla") {
    return <Tesla fill={color} {...style} />;
  }

  if (type === "moped") {
    return <Moped fill={color} {...style} />;
  }

  return <Car fill={color} {...style} />;
}
